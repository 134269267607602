<template>
  <main class="account">
    <h1>회원정보 수정</h1>
    <ValidationObserver v-slot="{ invalid }">
      <form @submit="onSubmit">
        <div class="form_content">
          <p>아이디</p>
          <input
            class="readonly"
            type="text"
            :placeholder="item.loginid"
            readonly
          />
        </div>
        <div class="form_content">
          <p>소속/단체명</p>
          <input
            class="readonly"
            type="text"
            :placeholder="item.corporation ? item.corporation.name : ''"
            readonly
          />
        </div>
        <div class="form_content">
          <p>사업자등록번호</p>
          <input
            class="readonly"
            type="text"
            :placeholder="item.corporation ? item.corporation.number : ''"
            readonly
          />
        </div>
        <div class="form_content">
          <p>담당자 성함</p>
          <ValidationProvider
            name="담당자 성함"
            rules="required"
            v-slot="{ errors }"
          >
            <input
              type="text"
              placeholder="성함을 입력해주세요."
              :disabled="submitStatus === 'PENDING'"
              v-model="name"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="form_content">
          <p>전화번호</p>
          <ValidationProvider
            name="전화번호"
            rules="required|numeric"
            v-slot="{ errors }"
          >
            <input
              type="text"
              placeholder="(-) 없이, 숫자만 입력해주세요."
              :disabled="submitStatus === 'PENDING'"
              v-model="tel"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="form_content">
          <p>이메일주소</p>
          <span class="reason">아이디 찾기, 비밀번호 찾기 시 필요합니다.</span>
          <ValidationProvider
            name="이메일주소"
            rules="required|email"
            v-slot="{ errors }"
          >
            <input
              type="email"
              placeholder="이메일주소를 입력해주세요."
              :disabled="submitStatus === 'PENDING'"
              v-model="email"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="btns">
          <base-button type="secondary" @click="$router.go(-1)"
            >이전</base-button
          >
          <base-button
            type="primary"
            nativeType="submit"
            :disabled="invalid || submitStatus === 'PENDING' || !isChanged"
            >{{
              submitStatus === "PENDING" ? "수정중.." : "수정하기"
            }}</base-button
          >
        </div>
      </form>
    </ValidationObserver>
  </main>
</template>
<script>
import { mapGetters } from "vuex";
import client from "api-client";
import BaseButton from "@/components/core/BaseButton.vue";
import Dialog from "@/components/Dialog.vue";

export default {
  components: { BaseButton },
  data() {
    return {
      submitStatus: null,
      item: {},
      name: "",
      tel: "",
      email: "",
      isChanged: false,
      beforeName: "",
      beforeTel: "",
      beforeEmail: "",
    };
  },
  created() {
    this.fetch();
  },
  computed: {
    ...mapGetters(["isAuthenticated", "getUser"]),
  },
  watch: {
    name: function (newName, oldName) {
      if (oldName == "" || this.beforeName == newName) {
        this.isChanged = false;
      } else {
        this.isChanged = true;
      }
    },
    tel: function (newTel, oldTel) {
      if (oldTel == "" || this.beforeTel == newTel) {
        this.isChanged = false;
      } else {
        this.isChanged = true;
      }
    },
    email: function (newEmail, oldEmail) {
      if (oldEmail == "" || this.beforeEmail == newEmail) {
        this.isChanged = false;
      } else {
        this.isChanged = true;
      }
    },
  },
  methods: {
    fetch() {
      client.userFindOne(this.getUser.id).then(
        (response) => {
          const { data } = response;
          this.item = data;
          this.name = data.name;
          this.tel = data.tel;
          this.email = data.email;
          this.beforeName = data.name;
          this.beforeTel = data.tel;
          this.beforeEmail = data.email;
        },
        (message) => {
          alert(message);
        }
      );
    },
    async onSubmit(evt) {
      evt.preventDefault();
      this.submitStatus = "PENDING";

      let params = {};
      if (this.name != this.item.name) {
        params.name = this.name;
      }
      if (this.tel != this.item.tel) {
        params.tel = this.tel;
      }
      if (this.email != this.item.email) {
        params.email = this.email;
      }
      await this.$store
        .dispatch("account", params)
        .then((response) => {
          this.submitStatus = "OK";
          if (response.status == 200) {
            this.$modal.show(
              Dialog,
              {
                title: "알림",
                content: "회원님의 정보가 정상적으로 수정되었습니다.",
              },
              {
                adaptive: true,
                width: "90%",
                maxWidth: 600,
                height: "auto",
              },
              {
                "before-open": () => {},
                "before-close": () => {},
                closed: () => {
                  this.$router.go(-1);
                },
              }
            );
          }
        })
        .catch((message) => {
          this.submitStatus = "ERROR";
          this.$modal.show(
            Dialog,
            {
              title: "알림",
              content: message,
            },
            {
              adaptive: true,
              width: "90%",
              maxWidth: 600,
              height: "auto",
            },
            {
              "before-open": () => {},
              "before-close": () => {},
            }
          );
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
