var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"account"},[_c('h1',[_vm._v("회원정보 수정")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":_vm.onSubmit}},[_c('div',{staticClass:"form_content"},[_c('p',[_vm._v("아이디")]),_c('input',{staticClass:"readonly",attrs:{"type":"text","placeholder":_vm.item.loginid,"readonly":""}})]),_c('div',{staticClass:"form_content"},[_c('p',[_vm._v("소속/단체명")]),_c('input',{staticClass:"readonly",attrs:{"type":"text","placeholder":_vm.item.corporation ? _vm.item.corporation.name : '',"readonly":""}})]),_c('div',{staticClass:"form_content"},[_c('p',[_vm._v("사업자등록번호")]),_c('input',{staticClass:"readonly",attrs:{"type":"text","placeholder":_vm.item.corporation ? _vm.item.corporation.number : '',"readonly":""}})]),_c('div',{staticClass:"form_content"},[_c('p',[_vm._v("담당자 성함")]),_c('ValidationProvider',{attrs:{"name":"담당자 성함","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],attrs:{"type":"text","placeholder":"성함을 입력해주세요.","disabled":_vm.submitStatus === 'PENDING'},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form_content"},[_c('p',[_vm._v("전화번호")]),_c('ValidationProvider',{attrs:{"name":"전화번호","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tel),expression:"tel"}],attrs:{"type":"text","placeholder":"(-) 없이, 숫자만 입력해주세요.","disabled":_vm.submitStatus === 'PENDING'},domProps:{"value":(_vm.tel)},on:{"input":function($event){if($event.target.composing){ return; }_vm.tel=$event.target.value}}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form_content"},[_c('p',[_vm._v("이메일주소")]),_c('span',{staticClass:"reason"},[_vm._v("아이디 찾기, 비밀번호 찾기 시 필요합니다.")]),_c('ValidationProvider',{attrs:{"name":"이메일주소","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],attrs:{"type":"email","placeholder":"이메일주소를 입력해주세요.","disabled":_vm.submitStatus === 'PENDING'},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"btns"},[_c('base-button',{attrs:{"type":"secondary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("이전")]),_c('base-button',{attrs:{"type":"primary","nativeType":"submit","disabled":invalid || _vm.submitStatus === 'PENDING' || !_vm.isChanged}},[_vm._v(_vm._s(_vm.submitStatus === "PENDING" ? "수정중.." : "수정하기"))])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }